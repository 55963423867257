import React, { Component } from "react";
import { Form, Segment, Button, Message } from "semantic-ui-react";
import axios from "./axios";

import { Redirect } from "react-router-dom";

export default class Login extends Component {
	state = {
		redirectToReferrer: false,
		err: {},
		password: "",
		name: "",
		isLoading: false
	};

	componentDidMount() {
		this.setState({ isLoading: false });
	}

	onLogin = () => {
		const { name, password } = this.state;
		this.setState({ isLoading: true });
		axios
			.post("api/users/login", {
				email: name,
				password: password
			})
			.then(res => {
				localStorage.setItem("auth", res.data.token);
				localStorage.setItem("role", res.data.role);
				this.setState({ redirectToReferrer: true });
			})
			.catch(err => {
				this.setState({ err: err.response.data, isLoading: false });
			});
	};
	nameChange = e => {
		this.setState({ name: e.target.value });
	};

	passwordChange = e => {
		this.setState({ password: e.target.value });
	};

	render() {
		const { redirectToReferrer, err, isLoading } = this.state;
		if (redirectToReferrer) {
			return <Redirect to="/home" />;
		}

		return (
			<div
				style={{
					display: "grid",
					alignItems: "center",
					height: "100vh"
				}}
			>
				<Form style={{ maxWidth: "20rem", justifySelf: "center" }}>
					<Message
						visible={
							err.hasOwnProperty("password") ||
							err.hasOwnProperty("email")
								? true
								: false
						}
						error
					>
						{err.hasOwnProperty("password") ? err.password : null}
						{err.hasOwnProperty("email") ? err.email : null}
					</Message>
					<Segment>
						<Form.Input
							placeholder="Username"
							onChange={this.nameChange}
							error={err.hasOwnProperty("email") ? true : false}
						/>
						<Form.Input
							placeholder="Password"
							type="password"
							onChange={this.passwordChange}
							error={
								err.hasOwnProperty("password") ? true : false
							}
						/>
						<Button
							loading={isLoading ? true : false}
							onClick={this.onLogin}
							style={{ width: "100%" }}
						>
							Login
						</Button>
					</Segment>
					{isLoading ? (
						<Message>Spinning up Heroku...</Message>
					) : null}
				</Form>
			</div>
		);
	}
}
