import React, { Component } from "react";
import {
	Segment,
	Table,
	Menu,
	Header,
	Icon,
	Modal,
	Form,
	Button
} from "semantic-ui-react";
import axios from "../axios";

export default class Gameweeks extends Component {
	state = {
		totalComps: [
			"EPL",
			"La Liga",
			"UCL",
			"Liga MX Apertura",
			"MLS",
			"Brasil"
		],
		activeComp: "EPL",
		activeCompId: 17,
		compMap: {
			EPL: 17,
			"La Liga": 19,
			UCL: 20,
			"Liga MX Apertura": 18,
			MLS: 15,
			Brasil: 16
		},
		gameweeks: [],
		modalOpen: false,
		gameweekFocusIndex: 0,
		newGw: false
	};

	openModal = () => this.setState({ modalOpen: true });
	closeModal = () => (
		this.setState({ modalOpen: false }), this.loadGameweeks()
	);

	componentDidMount() {
		this.loadGameweeks();
	}

	loadGameweeks = () => {
		const { activeCompId } = this.state;
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		axios
			.get(`/api/admin/gameweeks?comp=${activeCompId}`, header)
			.then(gws => {
				this.setState({
					gameweeks: gws.data
				});
			});
	};

	handleCompChange = (e, { name }) => {
		this.setState({ activeComp: name });
		this.setState({ activeCompId: this.state.compMap[name] }, () =>
			this.loadGameweeks()
		);
	};

	editGameweek = index => {
		this.setState({ newGw: false });
		const { gameweeks } = this.state;
		const startArr = gameweeks[index].start.split("T");
		const startParsed = `${startArr[0]} ${startArr[1].split(".")[0]}`;
		const endArr = gameweeks[index].end.split("T");
		const endParsed = `${endArr[0]} ${endArr[1].split(".")[0]}`;
		console.log(startParsed);

		this.setState({
			gameweekFocusIndex: index,
			start: startParsed,
			end: endParsed
		});
		this.openModal();
	};

	formSubmit = () => {
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};

		const {
			gameweekFocusIndex,
			gameweeks,
			start,
			end,
			newGw,
			activeCompId
		} = this.state;

		const body = {
			start,
			end,
			comp: activeCompId
		};

		if (newGw === true) {
			axios
				.post(`api/admin/new/gameweek`, body, header)
				.then(res => this.closeModal());
		} else {
			axios
				.post(
					`api/admin/gameweek/${gameweeks[gameweekFocusIndex]._id}`,
					body,
					header
				)
				.then(res => this.closeModal())
				.catch(res => console.log(res));
		}
		this.loadGameweeks();
	};

	formChange = e => this.setState({ [e.target.name]: e.target.value });

	newGameweekClick = () => {
		this.setState({ newGw: true });
		this.openModal();
	};

	deleteGameweek = id => {
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		axios
			.delete(`api/admin/gameweek/${id}`, header)
			.then(res => this.loadGameweeks());
	};

	render() {
		const {
			totalComps,
			activeComp,
			gameweeks,
			modalOpen,
			gameweekFocusIndex
		} = this.state;

		return (
			<Segment>
				<Header>Gameweeks</Header>
				<Menu secondary>
					{totalComps.map((comp, i) => (
						<Menu.Item
							key={i}
							name={comp}
							active={activeComp === comp}
							onClick={this.handleCompChange}
						>
							{comp}
						</Menu.Item>
					))}
				</Menu>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Gameweek</Table.HeaderCell>
							<Table.HeaderCell>Start</Table.HeaderCell>
							<Table.HeaderCell>End</Table.HeaderCell>
							<Table.HeaderCell />
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{gameweeks.map((gw, i) => (
							<Table.Row key={i}>
								<Table.Cell>{i}</Table.Cell>
								<Table.Cell>
									{gw.start.split("T")[0] +
										" at " +
										gw.start.split("T")[1].split(".")[0]}
								</Table.Cell>
								<Table.Cell>
									{gw.end.split("T")[0] +
										" at " +
										gw.end.split("T")[1].split(".")[0]}
								</Table.Cell>
								<Table.Cell>
									<Icon
										link
										name="edit"
										onClick={e => this.editGameweek(i)}
									/>
								</Table.Cell>
								<Table.Cell>
									<Icon
										name="delete calendar"
										link
										color="red"
										onClick={e =>
											this.deleteGameweek(gw._id)
										}
									/>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<Icon
					size="large"
					link
					circular
					inverted
					color="black"
					name="add to calendar"
					style={{
						marginTop: "1rem",
						marginLeft: "auto",
						display: "inherit"
					}}
					onClick={this.newGameweekClick}
				/>
				<Modal open={modalOpen} onClose={this.closeModal}>
					<Modal.Content>
						<Form>
							<Form.Field>
								Start
								<input
									value={this.state.start}
									type="datetime"
									name="start"
									onChange={this.formChange}
								/>
							</Form.Field>
							<Form.Field>
								End
								<input
									value={this.state.end}
									type="datetime"
									name="end"
									onChange={this.formChange}
								/>
							</Form.Field>
							<Button
								type="submit"
								floated="right"
								style={{ marginBottom: "1rem" }}
								onClick={this.formSubmit}
							>
								Submit
							</Button>
						</Form>
					</Modal.Content>
				</Modal>
			</Segment>
		);
	}
}
