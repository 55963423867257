import React, { Component } from "react";
import { Segment, Header, Table, Button, Input } from "semantic-ui-react";
import Axios from "../axios";

export default class Users extends Component {
	state = {
		users: [],
		email: "",
		name: "",
		role: "",
		editing: null,
		editField: ""
	};

	componentDidMount() {
		this.getUsers();
	}

	getUsers = () => {
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		Axios.get(`api/admin/users`, header).then(res =>
			this.setState({ users: [...this.state.users, ...res.data] })
		);
	};

	onChange = ({ target }, field) => this.setState({ [field]: target.value });

	editableCell = (field, user, i) => {
		const { editing, editField } = this.state;
		if (editing === i && editField === field) {
			return (
				<Table.Cell
					onClick={e =>
						this.setState({
							editing: i,
							editField: field
						})
					}
				>
					<Input
						placeholder={user[field]}
						onChange={e => this.onChange(e, field)}
					/>
				</Table.Cell>
			);
		} else {
			return (
				<Table.Cell
					onClick={e =>
						this.setState({
							editing: i,
							editField: field
						})
					}
				>
					{user[field]}
				</Table.Cell>
			);
		}
	};

	render() {
		const { users } = this.state;
		return (
			<Segment>
				<Header>Users</Header>
				<Table fixed>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={4}>Name</Table.HeaderCell>
							<Table.HeaderCell width={4}>
								Username
							</Table.HeaderCell>
							<Table.HeaderCell width={4}>Role</Table.HeaderCell>
							<Table.HeaderCell width={2}>Date</Table.HeaderCell>
							<Table.HeaderCell width={2} />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{users.map((user, i) => (
							<Table.Row key={i}>
								{this.editableCell("name", user, i)}
								{this.editableCell("email", user, i)}
								{this.editableCell("role", user, i)}
								<Table.Cell>
									{user.date.split("T")[0]}
								</Table.Cell>
								<Table.Cell>
									<Button
										onClick={e =>
											this.setState({ editing: null })
										}
										floated="right"
									>
										Save
									</Button>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Segment>
		);
	}
}
