import React, { Component } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import { CSVLink, CSVDownload } from "react-csv";

export default class Changer extends Component {
	state = {
		tierMap: ["T1", "T2", "T3"],
		positionMap: { MID: "M", FWD: "F", DEF: "D", UNAS: "NA", UNGK: "NAGK" }
	};

	componentDidMount() {
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth();
		this.setState({ date: `${day}-${month}` });
	}

	getCsv = () => {
		const { tierMap, positionMap } = this.state;
		let idsAndTiers = this.props.changes.map((p, i) => {
			if (p.position === "UNAS") return [p.playerid, "NA"];
			if (p.position === "UNGK") return [p.playerid, "NAGK"];
			if (p.position === "GK") return [p.playerid, "GK"];
			else return [p.playerid, tierMap[p.tier] + positionMap[p.position]];
		});
		return idsAndTiers;
	};

	render() {
		const {
			open,
			changes,
			onClose,
			removeChange,
			admin,
			comp
		} = this.props;

		return (
			<div>
				<Modal open={open} onClose={onClose}>
					<Modal.Header>Changes made this gameweek:</Modal.Header>
					<Modal.Content>
						{changes.length > 0 ? (
							<table style={{ width: "100%" }}>
								<tbody>
									<tr>
										{admin ? <th>PlayerID</th> : null}
										<th>Opta Code</th>
										<th>Player Name</th>
										<th>Last GW</th>
										<th />
										<th />
										<th>Upcoming GW</th>
										<th />
									</tr>
									{changes.map((p, i) => {
										return (
											<tr key={i}>
												{admin ? (
													<td>{p.playerid}</td>
												) : null}
												<td>{p.playercode}</td>
												<td>{p.player}</td>
												<td>
													{p.history[1].position}{" "}
												</td>
												<td>
													{p.history[1].position ===
														"UNAS" ||
													p.history[1].position ===
														"UNGK"
														? null
														: p.history[1].tier +
														  1}{" "}
												</td>
												<td>
													<Icon name="arrow right" />
												</td>
												<td>{p.position} </td>
												<td>
													{p.position === "UNAS"
														? null
														: p.tier + 1}
												</td>
												<td>
													<Button
														onClick={e =>
															removeChange(p._id)
														}
														negative
														size="tiny"
														floated="right"
													>
														x
													</Button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						) : (
							"No changes made this gameweek"
						)}
					</Modal.Content>
					<Modal.Actions>
						{admin ? (
							<CSVLink
								data={this.getCsv()}
								filename={`tier_changes_${comp}_${
									this.state.date
								}.csv`}
								enclosingCharacter=""
							>
								Download CSV
							</CSVLink>
						) : null}

						<Button negative onClick={onClose}>
							Close
						</Button>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}
