import React, { Component } from "react";
import {
	Segment,
	Container,
	Header,
	Menu,
	Modal,
	Button,
	Input,
	Grid,
	Icon,
	Checkbox
} from "semantic-ui-react";
import { Redirect } from "react-router-dom";

import Tier from "./Tier";
import Changes from "./Changes";
import ESPNHeader from "./ESPNHeader";

import axios from "./axios";
import _ from "lodash";

export default class Main extends Component {
	state = {
		players: {
			FWD: [[], [], []],
			MID: [[], [], []],
			DEF: [[], [], []],
			GK: [[], [], []]
		},
		initialPlayers: {
			FWD: [[], [], []],
			MID: [[], [], []],
			DEF: [[], [], []],
			GK: [[], [], []]
		},
		initialUnassigned: {
			UNAS: [],
			UNGK: []
		},
		unassigned: {
			UNAS: [],
			UNGK: []
		},
		totalComps: [
			"EPL",
			"La Liga",
			"UCL",
			"Liga MX Apertura",
			"MLS",
			"Brasil"
		],
		activeComp: "EPL",
		activeCompId: 9,
		totalPages: ["FWD", "MID", "DEF", "GK"],
		activeItem: "FWD",
		tiers: [
			[
				["Tier One Forward", 0, 2, "FWD"],
				["Tier Two Forward", 1, 2, "FWD"]
			],
			[
				["Tier One Midfielder", 0, 3, "MID"],
				["Tier Two Midfielder", 1, 3, "MID"],
				["Tier Three Midfielder", 2, 3, "MID"]
			],
			[
				["Tier One Defender", 0, 2, "DEF"],
				["Tier Two Defender", 1, 2, "DEF"]
			],
			[["Goalkeeper", 0, 1, "GK"]]
		],
		tiermap: {
			FWD: 2,
			MID: 3,
			DEF: 2,
			GK: 1
		},
		compmap: {
			EPL: 17,
			"La Liga": 19,
			UCL: 20,
			"Liga MX Apertura": 18,
			MLS: 15,
			Brasil: 16
		},
		open: false,
		isActive: true,
		direction: false,
		changes: {
			OUT: [[], [], []],
			UN: [[]]
		},
		redirectToReferrer: false,
		changesOpen: false,
		changesThisGameweek: [],
		time: {
			days: "",
			hours: "",
			minutes: ""
		}
	};

	closeChanges = () => {
		this.populatePlayerList();
		this.setState({ changesOpen: false });
	};
	openChanges = () => {
		this.getChanges();
		this.setState({ changesOpen: true });
	};
	show = () => this.setState({ size: "tiny", open: true });
	close = () => this.setState({ open: false });
	showErr = () => this.setState({ size: "tiny", openErr: true });
	closeErr = () => this.setState({ openErr: false });

	populatePlayerList = () => {
		const isActive = true;
		this.setState({ isActive });
		const comp = this.state.compmap[this.state.activeComp];
		let p1 = this.addPlayer(comp, "FWD", 0);
		let p2 = this.addPlayer(comp, "FWD", 1);
		let p3 = this.addPlayer(comp, "MID", 0);
		let p4 = this.addPlayer(comp, "MID", 1);
		let p5 = this.addPlayer(comp, "MID", 2);
		let p6 = this.addPlayer(comp, "DEF", 0);
		let p7 = this.addPlayer(comp, "DEF", 1);
		let p8 = this.addPlayer(comp, "GK", 0);
		let p9 = this.addPlayer(comp, "UNAS", 0);
		let p10 = this.addPlayer(comp, "UNGK", 0);

		Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10]).then(() => {
			const players = _.cloneDeep(this.state.initialPlayers);
			const unassigned = _.cloneDeep(this.state.initialUnassigned);
			const isActive = false;
			this.setState({ players, unassigned, isActive });
		});
	};

	componentDidMount() {
		this.populatePlayerList();
		this.getTimeRemaining();
	}

	handleSort = (position, tier, column, direction) => {
		const { players, unassigned } = this.state;

		if (position === "UNAS" || position === "UNGK") {
			let sortPlayers = _.sortBy([...unassigned[position]], column);
			if (direction) sortPlayers.reverse();

			this.setState(state => {
				state.unassigned[position] = [...sortPlayers];
				return state;
			});
		} else {
			let sortPlayers = _.sortBy([...players[position][tier]], column);
			if (direction) sortPlayers.reverse();

			this.setState(state => {
				state.players[position][tier] = [...sortPlayers];
				return state;
			});
		}
	};

	makeUnassigned = (player, data) => {
		const { position, tier } = player;
		const { unassigned, players } = this.state;
		const index = players[position][tier].findIndex(
			p => p._id === player._id
		);

		player.position = data.value;
		player.tier = 0;
		players[position][tier].splice(index, 1);
		if (player.position === "GK") {
			unassigned.UNGK.push(player);
		} else {
			unassigned.UNAS.push(player);
		}
		this.setState({ unassigned, players });
	};

	addPlayer = (comp, position, tier) => {
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		return new Promise((resolve, reject) => {
			axios
				.get(
					`/api/tiers/players?comp=${comp}&position=${position}&tier=${tier}`,
					header
				)
				.then(res => {
					if (position === "UNAS" || position === "UNGK") {
						const initialUnassigned = this.state.initialUnassigned;
						initialUnassigned[position] = res.data;
						this.setState({ initialUnassigned }, () => resolve());
					} else {
						const initialPlayers = this.state.initialPlayers;
						initialPlayers[position][tier] = res.data;
						this.setState({ initialPlayers }, () => resolve());
					}
				});
		});
	};

	getChanges = () => {
		const { compmap, activeComp } = this.state;
		axios
			.get(`api/tiers/changes?comp=${compmap[activeComp]}`)
			.then(res => this.setState({ changesThisGameweek: res.data }));
	};

	handleChange = (e, data, id, player) => {
		let { players, tiermap } = this.state;
		const { position, tier } = player;

		const index = players[position][tier].findIndex(
			player => player._id === id
		);

		if (data.value < tiermap[player.position]) {
			player.tier = data.value;
			players[position][tier].splice(index, 1);
			players[position][data.value].unshift(player);

			this.setState({ players });
		} else {
			const openErr = true;
			this.setState({ openErr });
		}
	};

	positionChange = (e, data, id, player) => {
		let { players, unassigned } = this.state;
		const { position, tier } = player;

		let index = null;
		if (position === "UNAS" || position === "UNGK") {
			index = unassigned[position].findIndex(player => player._id === id);
			unassigned[position].splice(index, 1);
		} else {
			index = players[position][tier].findIndex(
				player => player._id === id
			);
			players[position][tier].splice(index, 1);
		}

		player.position = data.value;
		if (data.value === "UNAS" || data.value === "UNGK") {
			unassigned[data.value].unshift(player);
		} else {
			players[data.value][tier].unshift(player);
		}

		this.setState({ players, unassigned });
	};

	onConfirm = position => {
		const { compmap, activeComp } = this.state;
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		axios
			.post(
				`/api/tiers/players/update?comp=${compmap[activeComp]}`,
				this.state.changes,
				header
			)
			.then(res => {
				this.populatePlayerList();
			})
			.catch(err => this.logout());
	};

	confirmAllTiers = () => {
		this.onConfirm();
		this.close();
	};

	/**
	 * Get difference from actual playerlist
	 * for this position. Unassigned changes is
	 * the changes made to the unassigned category, i.e.
	 * filter the current unassigned by players that are
	 * not in the initial list of unassigned players loaded from the db.
	 *
	 *
	 */
	getDiff = position => {
		const {
			players,
			initialPlayers,
			unassigned,
			initialUnassigned
		} = this.state;
		let changes = {};
		let un = position === "GK" ? "UNGK" : "UNAS";
		const unassignedChanges = unassigned[un].filter(player => {
			let exists = false;
			for (let i = 0; i < initialUnassigned[un].length; i++) {
				if (initialUnassigned[un][i]._id === player._id) {
					exists = true;
				}
			}
			if (!exists) {
				return true;
			} else {
				return false;
			}
		});
		/**
		 * For this position and each tier, filter the
		 * list of initial players in those categories
		 * loaded from the db.
		 */
		let outfieldChanges = [];
		for (let tier = 0; tier < 3; tier++) {
			const changesToTier = players[position][tier].filter(player => {
				let exists = false;
				for (
					let i = 0;
					i < initialPlayers[position][tier].length;
					i++
				) {
					if (initialPlayers[position][tier][i]._id === player._id) {
						exists = true;
					}
				}
				if (!exists) {
					return true;
				} else {
					return false;
				}
			});
			outfieldChanges.push(changesToTier);
		}

		changes.UN = unassignedChanges;
		changes.OUT = outfieldChanges;
		this.setState({ changes }, () => {
			this.show();
		});
	};

	search = query => {
		const position = this.state.activeItem;
		const un = position === "GK" ? "UNGK" : "UNAS";
		for (let tier = 0; tier <= 2; tier++) {
			let players = [...this.state.initialPlayers[position][tier]];
			players = players.filter(
				player =>
					player.player.toLowerCase().search(query.toLowerCase()) !==
					-1
			);
			this.setState(state => {
				state.players[position][tier] = [...players];
				return state;
			});
		}

		let players = [...this.state.initialUnassigned[un]];
		players = players.filter(
			player =>
				player.player.toLowerCase().search(query.toLowerCase()) !== -1
		);
		this.setState(state => {
			state.unassigned[un] = [...players];
			return state;
		});
	};

	handlePaginationChange = (e, { activePage }) => {
		this.setState({ activePage });
	};

	handleItemClick = (e, { name }) => {
		this.setState({ activeItem: name });
	};

	handleCompChange = (e, { name }) => {
		this.setState({ activeComp: name }, () => {
			this.getTimeRemaining();
			this.populatePlayerList();
		});
	};

	logout = () => {
		localStorage.removeItem("auth");
		localStorage.removeItem("role");
		this.setState({ redirectToReferrer: true });
	};

	checkChangesLength = () => {
		const { changes } = this.state;
		let i = 0;
		changes.OUT.forEach(tier => {
			if (tier.length > 0) i++;
		});
		if (changes.UN.length > 0) i++;
		if (i === 0) {
			return <p>No changes to be saved</p>;
		} else {
			return;
		}
	};

	removeLastChange = id => {
		axios
			.get(`/api/tiers/removechange?id=${id}`)
			.then(res => this.getChanges());
	};

	getTimeRemaining = () => {
		const { compmap, activeComp } = this.state;

		axios.get(`/api/tiers/time?comp=${compmap[activeComp]}`).then(res => {
			this.setState({
				time: {
					days: res.data.days,
					hours: res.data.hours,
					minutes: res.data.minutes
				}
			});
		});
	};

	toggleMade = () => {
		this.setState({ changesMade: !this.state.changesMade }, () =>
			this.postChecks()
		);
	};
	toggleReady = () => {
		this.setState({ changesReady: !this.state.changesReady }, () =>
			this.postChecks()
		);
	};
	toggleStats = () => {
		this.setState({ stats: !this.state.stats }, () => this.postChecks());
	};

	postChecks = () => {
		const {
			stats,
			changesMade,
			changesReady,
			compmap,
			activeComp
		} = this.state;
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		const body = {
			stats,
			changesReady,
			changesMade
		};

		axios
			.post(`/api/tiers/gwchecks?comp=${compmap[activeComp]}`, body)
			.then(res =>
				this.setState({
					stats: res.stats,
					changesReady: res.changesReady,
					changesMade: res.changesMade
				})
			);
	};

	render() {
		const {
			totalPages,
			activeItem,
			players,
			unassigned,
			tiers,
			size,
			open,
			openErr,
			isActive,
			direction,
			column,
			totalComps,
			activeComp,
			redirectToReferrer,
			changes,
			changesOpen,
			changesThisGameweek,
			time
		} = this.state;
		const activeIndex = totalPages.indexOf(activeItem);

		if (redirectToReferrer || !localStorage.getItem("auth")) {
			return <Redirect to="/" />;
		}

		return (
			<div>
				<Container style={{ padding: "2rem 0rem" }}>
					<Grid columns={2}>
						<Grid.Column>
							<ESPNHeader />
						</Grid.Column>

						<Grid.Column>
							<Header
								style={{
									textAlign: "right"
								}}
							>
								<Header.Subheader>
									Time until tiers lock: Days {time.days},
									Hours {time.hours}, Minutes {time.minutes}
								</Header.Subheader>
							</Header>
							<Button floated="right" onClick={this.logout}>
								Logout
							</Button>
							<Button
								positive
								floated="right"
								onClick={this.openChanges}
							>
								Changes This Week
							</Button>
							{localStorage.getItem("role") === "admin" ? (
								<Button href="/admin" floated="right" negative>
									Admin
								</Button>
							) : null}
						</Grid.Column>
					</Grid>

					<Menu secondary>
						{totalComps.map((comp, i) => (
							<Menu.Item
								key={i}
								name={comp}
								active={activeComp === comp}
								onClick={this.handleCompChange}
							>
								{comp}
							</Menu.Item>
						))}
					</Menu>

					<Grid columns={2}>
						<Grid.Column>
							<Menu secondary>
								{totalPages.map((position, i) => (
									<Menu.Item
										key={i}
										name={position}
										active={activeItem === position}
										onClick={this.handleItemClick}
									>
										{position}
									</Menu.Item>
								))}
							</Menu>
						</Grid.Column>
						<Grid.Column>
							<Input
								placeholder="Search"
								onKeyPress={e => {
									if (e.key === "Enter") {
										this.search(e.target.value);
									}
								}}
								style={{
									display: "grid",
									justifyItems: "right"
								}}
							/>
						</Grid.Column>
					</Grid>
					<Button
						fluid
						positive
						onClick={e => this.getDiff(activeItem)}
						style={{ marginTop: "0.75rem" }}
					>
						Confirm Tier Changes
					</Button>
					<Segment.Group>
						{tiers[activeIndex].map((tier, i) => {
							return (
								<Segment padded key={i}>
									<Tier
										tier={tier[0]}
										tierNum={tier[1]}
										tierSize={tier[2]}
										tierAbbr={tier[3]}
										players={players[tier[3]][tier[1]]}
										handleChange={this.handleChange}
										positionChange={this.positionChange}
										addPlayer={this.addPlayer}
										makeUnassigned={this.makeUnassigned}
										isActive={isActive}
										handleSort={this.handleSort}
										direction={direction}
										column={column}
										onConfirm={this.onConfirm}
										getDiff={this.getDiff}
									/>
								</Segment>
							);
						})}
						<Segment padded>
							<Tier
								tier={"Unassigned"}
								tierNum={0}
								tierAbbr={activeItem === "GK" ? "UNGK" : "UNAS"}
								players={
									activeItem === "GK"
										? unassigned.UNGK
										: unassigned.UNAS
								}
								positionChange={this.positionChange}
								isActive={isActive}
								handleSort={this.handleSort}
								direction={direction}
								column={column}
								onConfirm={this.onConfirm}
								getDiff={this.getDiff}
								makeUnassigned={() => null}
							/>
						</Segment>
					</Segment.Group>
				</Container>
				<Modal size={size} open={openErr} onClose={this.closeErr}>
					<Modal.Content>
						<p>There are not enough tiers in this position</p>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.closeErr} positive>
							Ok
						</Button>
					</Modal.Actions>
				</Modal>
				<Modal size={size} open={open} onClose={this.close}>
					<Modal.Header>Changes to be saved:</Modal.Header>
					<Modal.Content>
						{this.checkChangesLength()}
						{changes.OUT[0].map((change, i) => {
							return change ? (
								<p>
									{change.player} <Icon name="arrow right" />{" "}
									{change.position} {change.tier + 1}
								</p>
							) : null;
						})}
						{changes.OUT[1].map((change, i) => {
							return change ? (
								<p>
									{change.player} <Icon name="arrow right" />{" "}
									{change.position} {change.tier + 1}
								</p>
							) : null;
						})}
						{changes.OUT[2].map((change, i) => {
							return change ? (
								<p>
									{change.player} <Icon name="arrow right" />{" "}
									{change.position} {change.tier + 1}
								</p>
							) : null;
						})}
						{changes.UN.map((change, i) => {
							return change ? (
								<p>
									{change.player} <Icon name="arrow right" />{" "}
									{change.position}
								</p>
							) : null;
						})}
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.close} negative>
							Cancel
						</Button>
						<Button onClick={this.confirmAllTiers} positive>
							Ok
						</Button>
					</Modal.Actions>
				</Modal>

				<Changes
					onClose={this.closeChanges}
					open={changesOpen}
					changes={changesThisGameweek}
					removeChange={this.removeLastChange}
					admin={localStorage.getItem("role") === "admin"}
					comp={activeComp}
				/>
			</div>
		);
	}
}
