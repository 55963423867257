import axios from "axios";

const fetchClient = () => {
	const defaultOptions = {
		baseURL: "https://espn-tier-tool.herokuapp.com",
		method: "get",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*"
		}
	};

	// Create instance
	let instance = axios.create(defaultOptions);

	// Set the AUTH token for any request
	instance.interceptors.request.use(function(config) {
		const token = localStorage.getItem("auth");
		config.headers.Authorization = token ? token : "";
		return config;
	});

	return instance;
};

export default fetchClient();
