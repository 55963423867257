import React, { Component } from "react";
import {
	Table,
	Button,
	Grid,
	Header,
	Dropdown,
	Modal,
	Loader,
	Dimmer,
	Pagination
} from "semantic-ui-react";

export default class Tier extends Component {
	state = {
		open: false,
		activePage: 1,
		pageSize: 25,
		column: ""
	};

	confirmThenClose = (tierAbbr, tierNum, players) => {
		this.props.getDiff(tierAbbr, tierNum);

		this.props
			.onConfirm(tierAbbr, tierNum, players)
			.then(this.close)
			.catch(err => console.log(err));
	};

	handleSort = (position, tier, column) => {
		const { direction } = this.state;
		this.props.handleSort(position, tier, column, direction);
		this.setState({ direction: !direction, column: column });
	};

	show = () => this.setState({ size: "tiny", open: true });
	close = () => this.setState({ open: false });
	handlePaginationChange = (e, { activePage }) =>
		this.setState({ activePage });

	render() {
		const { tier, tierAbbr, tierNum, players, isActive } = this.props;
		const {
			open,
			size,
			activePage,
			pageSize,
			direction,
			column
		} = this.state;
		const player_page = players.slice(
			(activePage - 1) * pageSize,
			(activePage - 1) * pageSize + pageSize
		);

		const order = direction ? "ascending" : "descending";

		return (
			<div>
				<Grid columns={3}>
					<Grid.Column>
						<Header as="h3" content={tier} />
					</Grid.Column>
					<Grid.Column />
					<Grid.Column>
						{Math.ceil(players.length / pageSize) > 1 ? (
							<Pagination
								activePage={activePage}
								totalPages={Math.ceil(
									players.length / pageSize
								)}
								onPageChange={this.handlePaginationChange}
								floated="right"
							/>
						) : null}
					</Grid.Column>
				</Grid>
				<Grid stackable>
					<Grid.Column width={16}>
						<Dimmer active={isActive} inverted>
							<Loader />
						</Dimmer>
						<Table
							sortable
							celled
							striped
							size="small"
							color="blue"
						>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Tier</Table.HeaderCell>
									<Table.HeaderCell>Postion</Table.HeaderCell>
									<Table.HeaderCell>
										OPTA Code
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "player" ? order : null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"player"
											)
										}
									>
										Name
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "team" ? order : null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"team"
											)
										}
									>
										Team
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "upcoming" ? order : null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"upcoming"
											)
										}
									>
										Upcoming Match
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "proj" ? order : null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"proj"
											)
										}
									>
										Proj
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "prob" ? order : null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"prob"
											)
										}
									>
										Prob
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "owned" ? order : null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"owned"
											)
										}
									>
										Owned
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={
											column === "totalpoints"
												? order
												: null
										}
										onClick={() =>
											this.handleSort(
												tierAbbr,
												tierNum,
												"totalpoints"
											)
										}
									>
										Pts
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{player_page.map((player, i) => {
									const optionsNum = [
										{ text: "1", value: 0 },
										{ text: "2", value: 1 },
										{ text: "3", value: 2 }
									];

									let optionsPos = [];
									if (
										tierAbbr === "GK" ||
										tierAbbr === "UNGK"
									) {
										optionsPos = [
											{ text: "GK", value: "GK" },
											{
												text: "UNASSIGNED",
												value: "UNGK"
											}
										];
									} else {
										optionsPos = [
											{ text: "FWD", value: "FWD" },
											{ text: "MID", value: "MID" },
											{ text: "DEF", value: "DEF" },
											{
												text: "UNASSIGNED",
												value: "UNAS"
											}
										];
									}

									let tierDisabled = false;
									let positionDisabled = false;
									if (
										player.position === "UNAS" ||
										player.position === "GK" ||
										player.position === "UNGK"
									) {
										tierDisabled = true;
									}

									return (
										<Table.Row key={i}>
											<Table.Cell collapsing>
												<Dropdown
													disabled={tierDisabled}
													fluid
													selection
													value={player.tier}
													options={optionsNum}
													onChange={(e, data) =>
														this.props.handleChange(
															e,
															data,
															player._id,
															player
														)
													}
												/>
											</Table.Cell>
											<Table.Cell textAlign="center">
												<Dropdown
													disabled={positionDisabled}
													fluid
													selection
													value={player.position}
													options={optionsPos}
													onChange={(e, data) => {
														if (
															data.value ===
															"UNAS"
														) {
															this.props.makeUnassigned(
																player,
																data
															);
														} else {
															this.props.positionChange(
																e,
																data,
																player._id,
																player
															);
														}
													}}
												/>
											</Table.Cell>
											<Table.Cell>
												{player.playercode}
											</Table.Cell>
											<Table.Cell>
												{player.player}
											</Table.Cell>
											<Table.Cell>
												{player.team}
											</Table.Cell>
											<Table.Cell>
												{player.upcoming}
											</Table.Cell>
											<Table.Cell
												textAlign="center"
												collapsing
											>
												{player.proj}
											</Table.Cell>
											<Table.Cell
												textAlign="center"
												collapsing
											>
												{Math.floor(player.prob * 100)}%
											</Table.Cell>
											<Table.Cell
												textAlign="center"
												collapsing
											>
												{player.owned}%
											</Table.Cell>
											<Table.Cell
												textAlign="center"
												collapsing
											>
												{player.totalpoints}
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid>
				<Modal size={size} open={open} onClose={this.close}>
					<Modal.Header>Publish Changes</Modal.Header>
					<Modal.Content>
						<p>Are you sure you want to publish these changes?</p>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={this.close} negative>
							No
						</Button>
						<Button
							positive
							icon="checkmark"
							labelPosition="right"
							content="Yes"
							onClick={e => {
								this.confirmThenClose(
									tierAbbr,
									tierNum,
									players
								);
							}}
						/>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}
