import React, { Component } from "react";
import CreateUser from "./CreateUser";
import UpdatePlayerStats from "./UpdatePlayerStats";
import Gameweeks from "./Gameweeks";
import ESPNHeader from "../ESPNHeader";
import Users from "./Users";

import { Grid, Button, Container } from "semantic-ui-react";

export default class Admin extends Component {
	render() {
		return (
			<div>
				<Container style={{ padding: "2rem 0rem" }}>
					<Grid columns={2}>
						<Grid.Column>
							<ESPNHeader />
						</Grid.Column>
						<Grid.Column>
							<Button href="/home" floated="right" positive>
								Home
							</Button>
						</Grid.Column>
					</Grid>
					<UpdatePlayerStats />
					<Gameweeks />
					<CreateUser />
					<Users />
				</Container>
			</div>
		);
	}
}
