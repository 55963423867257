import React, { Component } from "react";
import Main from "./components/Main";
import Login from "./components/Login";
import Admin from "./components/admin/Admin";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			localStorage.getItem("auth") ? (
				<Component {...props} />
			) : (
				<Redirect to="/" />
			)
		}
	/>
);

const AdminRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			localStorage.getItem("role") === "admin" ? (
				<Component {...props} />
			) : (
				<Redirect to="/home" />
			)
		}
	/>
);

export default class App extends Component {
	render() {
		return (
			<Router>
				<div>
					<Route exact path="/" component={Login} />
					<PrivateRoute path="/home" component={Main} />
					<AdminRoute path="/admin" component={Admin} />
				</div>
			</Router>
		);
	}
}
