import React, { Component } from "react";
import {
	Segment,
	Button,
	Progress,
	Header,
	Label,
	Icon
} from "semantic-ui-react";
import axios from "../axios";

export default class UpdatePlayerStats extends Component {
	state = { selectedFile: null, loaded: 0 };

	handleFile = e => {
		this.setState({
			selectedFile: e.target.files[0],
			loaded: 0,
			error: null,
			update: false
		});
		e.target.value = "";
	};

	handleClick = e => {
		if (!this.state.selectedFile) {
			this.setState({ error: "Choose a file first" });
			setTimeout(() => this.setState({ error: null }), 1000);
			return;
		}

		this.setState({ error: null, update: true });
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			},
			onUploadProgress: ProgressEvent => {
				this.setState({
					loaded: (ProgressEvent.loaded / ProgressEvent.total) * 50
				});
			}
		};

		const data = new FormData();
		data.append(
			"file",
			this.state.selectedFile,
			this.state.selectedFile.name
		);

		axios
			.post("api/uploads/stats", data, header)
			.then(res => {
				this.setState({ selectedFile: null, loaded: 100 });
				setInterval(() => this.setState({ update: false }), 1500);
			})
			.catch(err => {
				this.setState({ error: err.response.data.err, loaded: 0 });
				setInterval(() => this.setState({ update: false }), 1500);
			});
	};

	render() {
		const { selectedFile, loaded, error, update } = this.state;

		return (
			<Segment>
				<Header>
					Update Player Stats
					<Header.Subheader>
						Get the CSV from here:{" "}
						<a
							href="https://espn.dreamleague.co.uk/CleverTV_espn/admin/adminRunReport.do?reportId=210"
							target="_blank"
							rel="noopener noreferrer"
						>
							ReportID 210
						</a>{" "}
						, Note that if a playercode is not a number then it will
						fail. UCL tends to have a few "dummy.." playercodes.
						Make sure that if you have edited the CSV, you save it
						as UTF-8 encoding.
					</Header.Subheader>
				</Header>
				<Label size="large" as="label" htmlFor="file">
					<Icon name="upload" />
					<Label.Detail>
						{selectedFile ? selectedFile.name : "Choose a file"}
					</Label.Detail>
				</Label>

				<input
					style={{ display: "none" }}
					type="file"
					name=""
					id="file"
					onChange={this.handleFile}
				/>
				<Button compact positive onClick={this.handleClick}>
					Update
				</Button>
				{!selectedFile && error ? (
					<Label color="red" pointing="left">
						{error}
					</Label>
				) : null}
				{update ? (
					<Progress
						style={{ marginTop: "2rem" }}
						active={update && !(loaded === 100)}
						percent={loaded}
						success={loaded === 100}
						error={error ? true : null}
					>
						{loaded === 100 && !error ? "Stats updated." : null}
						{error}
					</Progress>
				) : null}
			</Segment>
		);
	}
}
