import React, { Component } from "react";
import {
	Segment,
	Header,
	Input,
	Button,
	Grid,
	Checkbox
} from "semantic-ui-react";
import axios from "../axios";

const style = {
	Input: {
		width: "100%"
	},
	Checkbox: {},
	Button: {
		marginTop: "1rem",
		marginLeft: "auto",
		display: "inherit"
	}
};

export default class CreateUser extends Component {
	state = {
		name: "",
		user: "",
		password: "",
		checked: false
	};

	toggleAdmin = e => {
		this.setState({ checked: !this.state.checked });
	};

	onChange = ({ target }) => {
		const { name, value } = target;
		this.setState({ [name]: value });
	};

	onClick = () => {
		const { name, user, password, checked } = this.state;
		const header = {
			headers: {
				Authorization: localStorage.getItem("auth")
			}
		};
		const role = checked ? "admin" : "user";
		const body = {
			name,
			email: user,
			password,
			role
		};

		axios
			.post(`api/admin/register`, body, header)
			.then(res => this.setState({ name: "", email: "", role: "" }));
	};

	render() {
		const { name, user, password, checked } = this.state;

		return (
			<Segment>
				<Header>Create User</Header>
				<Grid columns={2}>
					<Grid.Row>
						<Grid.Column>
							<Input
								style={style.Input}
								placeholder="Name"
								onChange={this.onChange}
								name="name"
								value={name}
								autoComplete="off"
							/>
						</Grid.Column>
						<Grid.Column>
							<Input
								style={style.Input}
								placeholder="User"
								onChange={this.onChange}
								name="user"
								value={user}
								autoComplete="false"
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Input
								style={style.Input}
								placeholder="Password"
								type="password"
								onChange={this.onChange}
								name="password"
								value={password}
								autoComplete="false"
							/>
						</Grid.Column>
						<Grid.Column>
							<Checkbox
								label="Admin?"
								style={style.Checkbox}
								onChange={e => this.toggleAdmin(e)}
								checked={checked}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Button style={style.Button} onClick={this.onClick}>
					Submit
				</Button>
			</Segment>
		);
	}
}
