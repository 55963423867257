import React from "react";
import { Header, Image } from "semantic-ui-react";

const ESPNHeader = () => (
	<Header as="h2">
		<Image
			src="logo-espn.png"
			verticalAlign="bottom"
			style={{
				width: "7rem",
				padding: "0 0 6px 0"
			}}
		/>
		<Header.Content>Tier Tool</Header.Content>
	</Header>
);

export default ESPNHeader;
